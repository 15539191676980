import * as React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
//import Truncate from 'react-truncate'
import parse from 'html-react-parser'
import TruncateMarkup from 'react-truncate-markup'

const NewsPage = ({ data }) => {
    if (!data) return null
    const articles = data.allPrismicNews

    return (
        <Layout>
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-12 flex-1">
                            {articles.nodes.map((article, index) => {
                                const image = getImage(article.data.immagine_in_evidenza.localFile)
                                return (
                                    <div key={index} className="bg-white shadow-lg rounded-xl">
                                        { image ? (
                                        <GatsbyImage image={image} alt={article.data.titolo.text} className="rounded-t-xl" draggable="false" />
                                        ) : (
                                        <StaticImage src="../images/default-post-image.png" alt="default post image" placeholder="blurred" draggable="false" />
                                        )}
                                        <div className="p-6">
                                        <Link to={`/${article.uid}`}><h3 className="text-persian-green-500 text-lg mb-1">{article.data.titolo.text}</h3></Link>
                                            <TruncateMarkup lines={3} className="block mb-2">
                                               <div>
                                               {parse(article.data.contenuto.html)}
                                               </div>
                                            </TruncateMarkup>
                                            <Link to={`/${article.uid}`} className="bg-persian-green-500 bg-opacity-20 px-4 text-sm rounded-md hover:bg-opacity-100 transition-colors inline-flex text-persian-green-500 hover:text-white">Leggi tutto</Link>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default NewsPage

export const query = graphql`
  {
    allPrismicNews(sort: {fields: last_publication_date, order: DESC}) {
      nodes {
        uid
        id
        data {
          titolo {
            text
          }
          contenuto {
              html
          }
          immagine_in_evidenza {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
